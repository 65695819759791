<template>
  <a-drawer width="70%" :label-col="4" :wrapper-col="14" :visible="open" @close="cancel">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <div class="table-operations">
      <a-table
        :loading="loading"
        rowKey="columnName"
        :columns="columns"
        :data-source="tableData"
        :pagination="false">
        <span slot="status" slot-scope="text, record">
          <a-switch checked-children="开启" un-checked-children="关闭" @change=confirmHandleStatus(record) :checked="record.status===1" />
        </span>
        <span slot="direction" slot-scope="text, record">
            <!-- 重写自定义了个字典组件-->
           <custom-dict-tag :options="customDict.DirectionEnum"  :value="record.direction"/>
        </span>
      </a-table>
    </div>
  </a-drawer>

</template>

<script>

import {getMaskRoleData,saveMaskRoleData,changeMaskStatus} from "@/api/system/mask";
import { mapGetters } from 'vuex';
import CustomDictTag from "@/components/DictCustomTag";

export default {
  name: 'selectMask',
  components: {
    CustomDictTag,
  },
  data () {
    return {
      loading: false,
      open: false,
      columns: [
        {
          title: '列名',
          dataIndex: 'columnName',
          align: 'center'
        },
        {
          title: '列名描述',
          dataIndex: 'columnDesc',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '脱敏方向',
          dataIndex: 'direction',
          scopedSlots: { customRender: 'direction' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '开始位置',
          dataIndex: 'startIndex',
          align: 'center'
        },
        {
          title: '脱敏字符数',
          dataIndex: 'wordNum',
          align: 'center'
        },
        {
          title: '是否开启脱敏',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
          align: 'center'
        },
      ],
      tableData :[],
      formTitle: '',
      // 是否显示弹出层（数据权限）
      openMask: false,
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  methods: {

    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
    },
    /** 脱敏权限按钮操作 */
    handleMaskData(row) {
      getMaskRoleData(row.roleId).then(response => {
        this.tableData = response.data
        this.openMask = true
        this.open = true
        this.formTitle = '脱敏权限设置'
      });
    },
    cancelHandleStatus (row) {
    },
    // 脱敏权限状态修改
    confirmHandleStatus(row) {
      row.status = row.status === 0 ? 1 : 0
      saveMaskRoleData(row).catch(function() {
        row.status = row.status === 0 ? 1 : 0;
      });
      //刷新列表
    },
  }
}
</script>
